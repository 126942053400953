<template>
  <VOnboardingWrapper
    ref="wrapper"
    :steps="steps"
    :options="options"
    @exit="exitOnboarding"
  >
    <template #default="{ previous, next, step, exit, isLast, index }">
      <VOnboardingStep>
        <div class="content shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <div class="sm:flex sm:items-center sm:justify-between">
              <div v-if="step.content">
                <NuxtImg
                  v-if="step.content.image"
                  :src="step.content.image"
                  :alt="step.content.title"
                  class="tw-mx-auto tw-h-[200px] tw-w-[352px] tw-rounded-lg tw-object-contain sm:tw-h-[100px]"
                />
                <h3
                  v-if="step.content.title"
                  class="text-center tw-text-center tw-text-xl tw-font-semibold tw-capitalize tw-leading-[44px] tw-text-gray-900"
                >
                  {{ step.content.title }}
                </h3>
                <div
                  v-if="step.content.description"
                  class="mt-2 max-w-xl text-sm text-gray-500"
                >
                  <p
                    class="tw-text-center tw-text-[14px] tw-font-medium tw-text-gray-500"
                  >
                    {{ step.content.description }}
                  </p>
                </div>
              </div>

              <div
                class="mt-5 space-x-4 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center relative"
              >
                <div
                  class="tw-my-5 tw-flex tw-items-center tw-justify-center tw-gap-2"
                >
                  <div
                    v-for="(item, i) in steps"
                    :key="i"
                    class="tw-h-[8px] tw-w-[8px] tw-rounded-full tw-bg-gray-200"
                    :class="{ '!tw-bg-black': i <= index }"
                  ></div>
                </div>
                <!-- <span
                  class="absolute right-0 bottom-full mb-2 mr-2 text-gray-600 font-medium text-xs"
                  >{{ `${index + 1}/${steps.length}` }}</span
                > -->
              </div>

              <div class="actions">
                <AppButton
                  @click="exit"
                  class="action-button"
                  :full="true"
                  type="error"
                  >End</AppButton
                >
                <AppButton
                  @click="
                    () => {
                      index > 0 ? previous() : exit();
                    }
                  "
                  class="action-button"
                  :full="true"
                  type="outline"
                  >{{ index > 0 ? "Back" : "Skip" }}</AppButton
                >
                <AppButton
                  @click="next"
                  :full="true"
                  class="action-button"
                  type="dark"
                >
                  {{ isLast ? "Finish" : "Next" }}</AppButton
                >
              </div>
            </div>
          </div>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>

<script setup lang="ts">
const imgDashboard =
  "https://files.sproutly.africa/assets/images/tour/onboarding/dashboard.png";
const imgSettings =
  "https://files.sproutly.africa/assets/images/tour/onboarding/settings.png";
const imgInsight =
  "https://files.sproutly.africa/assets/images/tour/onboarding/insight-overview.png";
const imgAdmin =
  "https://files.sproutly.africa/assets/images/tour/onboarding/administration.png";
const imgFinance =
  "https://files.sproutly.africa/assets/images/tour/onboarding/finance.png";
const imgBursary =
  "https://files.sproutly.africa/assets/images/tour/onboarding/bursary.png";
const imgPayments =
  "https://files.sproutly.africa/assets/images/tour/onboarding/payment.png";
const imgRewards =
  "https://files.sproutly.africa/assets/images/tour/onboarding/rewards.png";

import {
  VOnboardingWrapper,
  VOnboardingStep,
  useVOnboarding,
} from "v-onboarding";

import "v-onboarding/dist/style.css";
import type { VOnboardingWrapperOptions } from "v-onboarding/src/types/VOnboardingWrapper";

const wrapper = ref(null);
const { start, goToStep, finish } = useVOnboarding(wrapper);

import { useStore } from "../../store";
const store = useStore();

const steps = [
  {
    attachTo: { element: "#getting-started" },
    content: {
      title: `Welcome, ${store?.active_school?.name ?? "Sprouter 🌱"}`,
      image: imgDashboard,
      description:
        "We’re glad to have you onboard. Here are some quick tips to get you up and running.",
    },
  },
  {
    attachTo: { element: "#settings" },
    content: {
      title: "Settings",
      image: imgSettings,
      description:
        "Your control room for your profile, compliance, groups, security, subscriptions, and payment settings.",
    },
  },
  {
    attachTo: { element: "#insight" },
    content: {
      title: "Insight Overview",
      image: imgInsight,
      description:
        "Oversee invoice payment and revenue trends over given periods of time. ",
    },
  },
  {
    attachTo: { element: "#administration" },
    content: {
      title: "Administration",
      image: imgAdmin,
      description:
        "View and Manager any and all billing accounts linked to your institution.",
    },
  },
  {
    attachTo: { element: "#finance" },
    content: {
      title: "Finance",
      image: imgFinance,
      description:
        "Create wallets and oversee wallet balance and transactions trends over given periods. ",
    },
  },
  {
    attachTo: { element: "#payments" },
    content: {
      title: "Payments",
      image: imgPayments,
      description:
        "Create and publish invoices and oversee invoice payment transactions from billing managers",
    },
  },
  {
    attachTo: { element: "#bursary" },
    content: {
      title: "Bursary",
      image: imgBursary,
      description:
        "Organise invoices into academic sessions and periods such as Terms, Semester, Trimester or Quarters",
    },
  },

  {
    attachTo: { element: "#rewards" },
    content: {
      title: "Rewards",
      image: imgRewards,
      description:
        "Create and publish invoices and oversee invoice payment transactions from billing managers",
    },
  },
];

const { $eventBus } = useNuxtApp();

$eventBus.$on("startTour", () => {
  start();
});

const exitOnboarding = () => {
  finish();
};
const options = {
  popper: {
    placement: "left",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  },
} as VOnboardingWrapperOptions;
</script>

<style>
[data-v-onboarding-wrapper] [data-popper-arrow]::before {
  content: "";
  background: var(--v-onboarding-step-arrow-background, white);
  top: 0;
  left: 0;
  transition:
    transform 0.2s ease-out,
    visibility 0.2s ease-out;
  visibility: visible;
  transform: translateX(0px) rotate(45deg);
  transform-origin: center;
  width: var(--v-onboarding-step-arrow-size, 10px);
  height: var(--v-onboarding-step-arrow-size, 10px);
  position: absolute;
  z-index: -1;
}

[data-v-onboarding-wrapper]
  [data-popper-placement^="top"]
  > [data-popper-arrow] {
  bottom: 5px;
}

[data-v-onboarding-wrapper]
  [data-popper-placement^="right"]
  > [data-popper-arrow] {
  left: -4px;
}

[data-v-onboarding-wrapper]
  [data-popper-placement^="bottom"]
  > [data-popper-arrow] {
  top: -4px;
}

[data-v-onboarding-wrapper]
  [data-popper-placement^="left"]
  > [data-popper-arrow] {
  right: -4px;
}

@media screen and (max-width: 1500px) {
  [data-popper-escaped] {
    transform: none !important;
    display: grid;
    place-content: center;
    width: 100%;
    height: 100%;
  }
  [data-popper-escaped] img {
    height: 100px;
  }
}

.content {
  background: white;
  box-shadow: 0px 20px 24px -4px #10182814;
  max-width: 400px;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  gap: 32px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.action-button {
  flex-grow: 1;
}
</style>
